.tk-hero {
  &__title {
    color: #fff;
    background-color: #00205b;
    padding: 0 8px 8px 8px;
    margin: 5px -10px;
    line-height: 180%;
    font-size: 42px;

    @include larger-than($mobile) {
      font-size: 54px;
    }
  }
}

.text-banner {
  height: auto;
  text-align: center;
  padding: 50px;
  max-width: 1080px;
  margin: auto;
  margin-left: 70px;
  margin-right: 70px;
  margin-top: 5px;
  margin-bottom: 5px;

  @media screen and (max-width: 768px) {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 5px;
    margin-bottom: 5px;
  }

  &__title {
    font-size: 22px;
    font-weight: bold;
  }

  &__text p {
    font-size: 18px;
  }

  &__bg-blue {
    background-color: #00205b;
    color: #fff;
  }

  &__bg-lightblue {
    background-color: #eff7fa;
    color: #00205b;
  }

  &__bg-lightgreen {
    background-color: #fafcf7;
    color: #00205b;
  }

  &__bg-white {
    background-color: #fff;
    color: #00205b;
  }

  &__bg-grey {
    background-color: #f2f2f2;
    color: #00205b;
  }

  &__ft-large {
    font-size: 1.5rem;
  }

  &__ft-medium {
    font-size: 1.25rem;
  }

  &__ft-small {
    font-size: 1rem;
  }
}

.m-banner__button {
  color: #00205b;
  border-radius: 0;
  background-color: #fff;
  text-shadow: none;
  padding: 5px 10px;
  max-height: 40px;
  visibility: hidden;

  &__rounded {
    padding: 5px 8px 10px 8px;
    border-radius: 100%;
  }

  &:hover {
    color: #d6001c;
  }
}

.m-banner--tinekjokken .m-banner__text-container.textPosition {
  &__top {
    top: 10%;
  }

  &__middle {
    top: 30%;
  }

  &__bottom {
    top: 51%;

    @include larger-than($mini) {
      top: 56%;
    }
    @include larger-than($mobile) {
      top: 38%;
    }
    @include larger-than($tablet) {
      top: 65%;
    }
    @include larger-than($desktop) {
      top: 65%;
    }
  }
}

#tk-hero__cta {
  color: #00205b;
  background-color: #fff;
  text-shadow: none;
  padding: 6px 12px;
  max-height: 40px;
  margin: 0 50%;
  left: -90px;
  width: 180px;
  max-width: 180px;

  @include larger-than($mobile) {
    padding: 5px 10px;
  }

  &:hover {
    color: #d6001c;
  }
}

#tk-cover {
  background: none;

  &:hover {
    background: none;
  }
}

.t-body-tema {
  margin: 0 20px 32px;
  text-align: left;

  @include larger-than($mobile) {
    margin: 0 120px 32px;
  }
}

.m-banner-arrow_down {
  border-radius: 100%;
  padding: 5px 8px 10px 8px;
  max-width: 44px !important; //this important take over the max-width from here #tk-hero__cta when the button is a tema page
}

.m-banner__inner .a-icon-arrow_down {
  margin-bottom: 10px;
}

.m-banner__inner .a-icon-arrow_right {
  display: none;
}

.m-banner__inner .m-banner__preface {
  visibility: hidden;
}

.m-banner--tinekjokken .m-banner__text-container .m-banner__category {
  background-color: #fff;
  padding: 5px;
  text-shadow: none;
  max-width: 140px;
  margin: auto;
}

.tk-hero__category {
  color: #00205b;
}

.m-banner__text-container {
  padding: 0 10px;
  @include larger-than($mobile) {
    padding: 0 120px;
  }
}
