$mobile-play-icon-size: 25px;
$mobile-play-icon-padding: 16px;
$regular-play-icon-size: 40px;
$regular-play-padding-size: 30px;
$hero-play-icon-size: 50px;
$hero-play-padding-size: 40px;

// TODO: Create a function which will take care of media queries

@mixin larger-than($breakpoint) {
  @media all and (min-width: $breakpoint) { @content; }
}

@mixin smaller-than($breakpoint) {
  // AKA smaller-than; IKKE BRUK DENNE MER ENN NØDVENDIG
  @media all and (max-width: $breakpoint) { @content; }
}

@mixin isMobile() {
  @media all and (max-width: $mobile - 1) { @content; }
}

@mixin isTablet() {
  @media (min-width: $mobile) and (max-width: $tablet - 1) { @content; }
}

@mixin play-icon() {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 2;
  border-radius: 50%;
  background-color: rgba($color-white, 0.6);
  border: 2px solid rgba($color-white, 0.6);
  background-clip: padding-box;
  outline: none;

  i {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-47%) translateY(-50%);
    color: #000;
  }
  padding: $mobile-play-icon-padding;

  > i {
    font-size: $mobile-play-icon-size;
  }

  &.o-hero__decorative-play-icon,
  &.o-header-media__play-icon {
    padding: $regular-play-padding-size;

    > i {
      font-size: $regular-play-icon-size;
    }
  }

  @include larger-than($mobile) {
    padding: $regular-play-padding-size;

    > i {
      font-size: $regular-play-icon-size;
    }

    &.o-hero__decorative-play-icon,
    &.o-header-media__play-icon {
      padding: $hero-play-padding-size;

      > i {
        font-size: $hero-play-icon-size;
      }
    }
  }
}

@mixin square-picture {
  display: block;
  margin-left: -25%;
  max-width: none;
  width: 150%;
}

@mixin wide-picture {
  margin-left: 0;
  width: 100%;
  max-width: 100%;
}

@mixin video-player() {
  .video-player {
    position: relative;
    padding-bottom: 9/16*100%;
    @content;

    > iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      @content;
    }
  }
}
