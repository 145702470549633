/* stylelint-disable */

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-filter:before {
  content: "\e932";
}
.icon-youtube:before {
  content: "\e92f";
}
.icon-oven:before {
  content: "\e92a";
}
.icon-minus:before {
  content: "\e917";
}
.icon-clock:before {
  content: "\e923";
}
.icon-skillhard:before {
  content: "\e925";
}
.icon-skilleasy:before {
  content: "\e926";
}
.icon-skillmedium:before {
  content: "\e927";
}
.icon-head:before {
  content: "\e920";
}
.icon-comments2:before {
  content: "\e922";
}
.icon-plus:before {
  content: "\e916";
}
.icon-ratingstarfilled:before {
  content: "\e938";
}
.icon-ratingstaroutline:before {
  content: "\e91c";
}
.icon-halloweenpumpkin:before {
  content: "\e914";
}
.icon-christmasbells:before {
  content: "\e90f";
}
.icon-christmastree2:before {
  content: "\e912";
}
.icon-snowcrystal:before {
  content: "\e913";
}
.icon-christmasball:before {
  content: "\e910";
}
.icon-vegetarian:before {
  content: "\e90e";
}
.icon-christmastree1:before {
  content: "\e911";
}
.icon-article:before {
  content: "\e900";
}
.icon-bread:before {
  content: "\e901";
}
.icon-breakfast:before {
  content: "\e902";
}
.icon-cake:before {
  content: "\e903";
}
.icon-dessert:before {
  content: "\e904";
}
.icon-dinner:before {
  content: "\e905";
}
.icon-drink:before {
  content: "\e906";
}
.icon-extras:before {
  content: "\e907";
}
.icon-lunch:before {
  content: "\e908";
}
.icon-sauce:before {
  content: "\e909";
}
.icon-smalldishes:before {
  content: "\e90a";
}
.icon-snacks:before {
  content: "\e90b";
}
.icon-theme:before {
  content: "\e90c";
}
.icon-tip:before {
  content: "\e90d";
}
.icon-arrow_down:before {
  content: "\e915";
  color: #00205b;
}
.icon-arrow_left:before {
  content: "\e918";
  color: #00205b;
}
.icon-arrow_right:before {
  content: "\e919";
  color: #00205b;
}
.icon-arrow_up:before {
  content: "\e91a";
  color: #00205b;
}
.icon-chef_hat:before {
  content: "\e91b";
  color: #47a23f;
}
.icon-close:before {
  content: "\e91d";
  color: #00205b;
}
.icon-comment:before {
  content: "\e91e";
  color: #a15a95;
}
.icon-facebook:before {
  content: "\e91f";
  color: #00205b;
}
.icon-heart:before {
  content: "\e921";
  color: #d6001c;
}
.icon-instagram:before {
  content: "\e924";
  color: #00205b;
}
.icon-mail:before {
  content: "\e928";
}
.icon-menu:before {
  content: "\e929";
  color: #00205b;
}
.icon-pintrest:before {
  content: "\e92b";
}
.icon-play:before {
  content: "\e92c";
  color: #00205b;
}
.icon-print:before {
  content: "\e92d";
}
.icon-shopping_cart:before {
  content: "\e92e";
}
.icon-search:before {
  content: "\e930";
  color: #00205b;
}
.icon-star:before {
  content: "\e931";
}
.icon-thumb_up:before {
  content: "\e933";
  color: #00205b;
}
.icon-twitter:before {
  content: "\e937";
  color: #00205b;
}
.icon-user:before {
  content: "\e936";
  color: #00205b;
}

/* stylelint-enable */
