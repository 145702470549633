@mixin active($active-text-color: $color-call-to-action-wb) {
  &:active {
    color: $active-text-color;
    @content;
  }
}

@mixin focus($focus-background-color: $color-31-ab, $focus-text-color: $color-call-to-action-wb) {
  &:focus {
    color: $focus-text-color;
    outline: 2px solid $focus-background-color;
    @content;
  }
}

@mixin hover($hover-color: $color-call-to-action-wb) {
  &:hover {
    color: $hover-color;
    @content;
  }
}

@mixin linkTextOnHover {
  color: $color-text-decoration-wb;
  text-decoration: underline;
}
