.o-button-link {
  &__collection {
    display: flex;
    margin-bottom: 2rem;
    padding: 0 0.5rem;
    overflow-x: auto;

    @include larger-than($tablet) {
      margin: 0 1.5rem 2rem;
      padding: 0 1rem;
    }
  }

  &__item {
    width: 33%;
    display: flex;
    list-style: none;
    border-radius: 8px;
    margin: 10px 5px;
    min-width: 55vw;

    @include larger-than(400px) {
      min-width: 44vw;
    }

    &:first-of-type {
      margin-left: 0;
    }

    &:last-of-type {
      margin-right: 0;
    }

    @include larger-than($mobile) {
      min-width: inherit;
      margin: 10px;
      border-radius: 0;
    }
  }

  &__icon {
    margin: 1rem 1rem 0;
    width: 40px;
    height: 40px;

    @include larger-than($mobile) {
      margin: 1.5rem 1.5rem 0;
      width: 75px;
      height: 75px;
    }
  }

  &__link {
    display: flex;
    flex-basis: 100%;
    align-items: center;
    flex-direction: column;
    text-decoration: none;
  }

  &__heading {
    text-align: center;
    font-size: 1.5rem;
    margin: 0.6rem 0;

    @include larger-than($mobile) {
      margin: 0.6rem 0 1rem;
      font-size: 2rem;
    }
  }

  &__description {
    text-align: center;
    margin: 0 0.75rem 1.25rem 0.75rem;
    font-size: 115%;

    @include larger-than($tablet) {
      margin: 0 2rem 2rem 2rem;
      font-size: 125%;
    }
  }
}
