
@font-face {
  font-family: 'Telefon';
  src: url('../fonts/TelefonWeb-Light.eot');
  src:
    url('../fonts/TelefonWeb-Light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/TelefonWeb-Light.woff2') format('woff2'),
    url('../fonts/TelefonWeb-Light.woff') format('woff'),
    local('Helvetica');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Telefon';
  src: url('../fonts/TelefonWeb-LightItalic.eot');
  src:
    url('../fonts/TelefonWeb-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/TelefonWeb-LightItalic.woff2') format('woff2'),
    url('../fonts/TelefonWeb-LightItalic.woff') format('woff'),
    local('Helvetica');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Telefon';
  src: url('../fonts/TelefonWeb-Blond.eot');
  src:
    url('../fonts/TelefonWeb-Blond.eot?#iefix') format('embedded-opentype'),
    url('../fonts/TelefonWeb-Blond.woff2') format('woff2'),
    url('../fonts/TelefonWeb-Blond.woff') format('woff'),
    local('Helvetica');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Telefon';
  src: url('../fonts/TelefonWeb-BlondItalic.eot');
  src:
    url('../fonts/TelefonWeb-BlondItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/TelefonWeb-BlondItalic.woff2') format('woff2'),
    url('../fonts/TelefonWeb-BlondItalic.woff') format('woff'),
    local('Helvetica');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Telefon';
  src: url('../fonts/TelefonWeb-Normal.eot');
  src:
    url('../fonts/TelefonWeb-Normal.eot?#iefix') format('embedded-opentype'),
    url('../fonts/TelefonWeb-Normal.woff2') format('woff2'),
    url('../fonts/TelefonWeb-Normal.woff') format('woff'),
    local('Helvetica');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Telefon';
  src: url('../fonts/TelefonWeb-NormalItalic.eot');
  src:
    url('../fonts/TelefonWeb-NormalItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/TelefonWeb-NormalItalic.woff2') format('woff2'),
    url('../fonts/TelefonWeb-NormalItalic.woff') format('woff'),
    local('Helvetica');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Telefon';
  src: url('../fonts/TelefonWeb-Medium.eot');
  src:
    url('../fonts/TelefonWeb-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/TelefonWeb-Medium.woff2') format('woff2'),
    url('../fonts/TelefonWeb-Medium.woff') format('woff'),
    local('Helvetica');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Telefon';
  src: url('../fonts/TelefonWeb-MediumItalic.eot');
  src:
    url('../fonts/TelefonWeb-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/TelefonWeb-MediumItalic.woff2') format('woff2'),
    url('../fonts/TelefonWeb-MediumItalic.woff') format('woff'),
    local('Helvetica');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Telefon';
  src: url('../fonts/TelefonWeb-Bold.eot');
  src:
    url('../fonts/TelefonWeb-Bold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/TelefonWeb-Bold.woff2') format('woff2'),
    url('../fonts/TelefonWeb-Bold.woff') format('woff'),
    local('Helvetica');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Telefon';
  src: url('../fonts/TelefonWeb-BoldItalic.eot');
  src:
    url('../fonts/TelefonWeb-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/TelefonWeb-BoldItalic.woff2') format('woff2'),
    url('../fonts/TelefonWeb-BoldItalic.woff') format('woff'),
    local('Helvetica');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Telefon';
  src: url('../fonts/TelefonWeb-Black.eot');
  src:
    url('../fonts/TelefonWeb-Black.eot?#iefix') format('embedded-opentype'),
    url('../fonts/TelefonWeb-Black.woff2') format('woff2'),
    url('../fonts/TelefonWeb-Black.woff') format('woff'),
    local('Helvetica');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Telefon';
  src: url('../fonts/TelefonWeb-BlackItalic.eot');
  src:
    url('../fonts/TelefonWeb-BlackItalic.eot?#iefix') format('embedded-opentype'),
    url('../fonts/TelefonWeb-BlackItalic.woff2') format('woff2'),
    url('../fonts/TelefonWeb-BlackItalic.woff') format('woff'),
    local('Helvetica');
  font-weight: 800;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icons/icomoon.eot');
  src:
    url('../fonts/icons/icomoon.eot?eb16e9#iefix') format('embedded-opentype'),
    url('../fonts/icons/icomoon.ttf?eb16e9') format('truetype'),
    url('../fonts/icons/icomoon.woff?eb16e9') format('woff'),
    url('../fonts/icons/icomoon.svg?eb16e9#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
