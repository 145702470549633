.o-layouts-thirty-seventy {
  @include container($front-page-width);
  margin-bottom: $horizontal-space;

  @include larger-than($tablet) {
    margin-top: $horizontal-space;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &__first {
      &--site {
        width: 30%;
      }
    }

    &__second {
      &--site {
        width: 70%;
      }
    }
  }
}
