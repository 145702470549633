
.m-osteskrin {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  position: relative;

  &__top-title {
    max-width: 1488px;
    margin: 0 auto;

    .m-section-heading__title {
      margin-left: $small-page-margin;
      margin-right: $small-page-margin;
    }
  }

  &__content {
    display: block;
    width: 100%;

    &--content-container {
      display: inline-block;
      width: 100%;
      @include larger-than($tablet) {
        width: 49%;
        padding: 24px 48px;
      }
    }

    &--image-container {
      display: inline-block;
      width: 100%;
      padding-top: 30px;

      img {
        width: 100%;
      }
      @include larger-than($tablet) {
        padding-top: 35px;
        width: 50%;
      }
    }
  }

  &__title {
    text-transform: uppercase;
    font-size: 32px;
    font-weight: 400;
  }

  &__link {
    max-width: 1488px;
    color: $color-text-lb;
    margin: 0 auto;
    display: block;
    padding: 0 12px;
    text-decoration: none;
    @include focus() {
      outline: none;
      color: $color-text-lb;

      .m-osteskrin__title {
        color: $color-text-decoration-wb;
        text-decoration: underline;
      }
    }
    @include hover($color-text-lb) {
      .m-card__content {
        @include opacityOnLinkedImage;
      }

      .m-osteskrin__title {
        @include linkTextOnHover;
        color: $color-text-lb;
      }
    }
  }
}
