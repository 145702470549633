@mixin card-list-layout-default {
  &:nth-child(1),
  &:nth-child(6n+6),
  &:nth-child(6n+7) {
    @content;
  }
}

@mixin card-list-layout-default-non {
  &:nth-child(6n+2),
  &:nth-child(6n+3),
  &:nth-child(6n+4),
  &:nth-child(6n+5) {
    @content;
  }
}

@mixin card-list-layout-tablet {
  &:nth-child(5n+1),
  &:nth-child(5n+2) {
    @content;
  }
}

@mixin card-list-layout-tablet-odd {
  &:nth-child(5n+1) {
    @content;
  }
}

@mixin card-list-layout-tablet-even {
  &:nth-child(5n+2) {
    @content;
  }
}

@mixin card-list-layout-tablet-non {
  // Velg ut barne-elementene som _ikke_ er utvalgt
  &:nth-child(5n+3),
  &:nth-child(5n+4),
  &:nth-child(5n+5) {
    @content;
  }
}

@mixin container($width: $page-max-width, $horizontal-space: $small-page-margin) {
  margin: 0 0;
  max-width: $width;

  @include larger-than($width + $horizontal-space * 2) {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
  }
}
