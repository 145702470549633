.o-error-page {
  position: relative;
  min-height: 450px;

  &__contents {
    @include container(650px);
    padding: $base-space * 16 $base-space * 2;
    width: 100%;
    margin: auto;

    h1 {
      font-weight: 500;
      text-transform: uppercase;
      margin-bottom: 0;
    }

    a {
      font-size: 24px;
    }

    p {
      font-size: 24px;
      margin-top: 0;
    }
  }

  &__tablecloth {
    transform: scaleX(-1);
    position: absolute;
    right: 0;
    bottom: 0;
    margin-bottom: -4%;
    width: 20%;

    @include larger-than($mobile) {
      max-width: 100px;
    }

    @include larger-than($tablet) {
      width: auto;
      max-width: none;
    }
  }
}
