/* critical:start */
$navigation-color: #151515;
$navigation-page-width: 740px;

.o-navigation-page {
  &__container {
    margin-top: $base-space * 5;

    @include larger-than($mobile) {
      max-width: $navigation-page-width;
      margin: $base-space * 5 auto;
    }
  }

  .o-search-results__sort__subtitle {
    font-weight: 500;
    padding: $base-space;
  }

  &__title {
    margin-left: $small-page-margin / 2;
    margin-bottom: $small-page-margin * 2;
    text-align: center;

    a {
      font-size: 1.5rem;
      text-decoration: none;
    }
  }

  &__topics {
    background-color: $color-light-background;

    .o-card-list__item:first-of-type {
      @include isTablet() {
        width: 100%;
      }
    }
  }

  &__list {
    list-style: none;
    padding-left: 0;

    @include larger-than($mobile) {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
    }

    &-item {
      @include isTablet() {
        flex-basis: 48%;
        text-align: center;
      }

      @include larger-than($tablet) {
        flex-basis: 32%;
        text-align: center;
      }

      &:first-child {
        border-top: 1px solid $color-content-separator;

        @include larger-than($mobile) {
          border-top: none;
        }
      }
    }
  }

  &__link {
    font-size: $font-size-medium;
    display: block;
    text-decoration: none;
    color: $navigation-color;
    padding: $small-page-margin / 2;
    border-bottom: 1px solid $color-content-separator;

    @include larger-than($mobile) {
      border: none;
      padding: $small-page-margin / 2;
      background: #f0eeeb;
      margin-bottom: $small-page-margin / 2;
      display: list-item;
      list-style-type: none;
    }

    &:hover {
      color: $navigation-color;
      filter: brightness(85%);
    }
  }
}
/* critical:end */
