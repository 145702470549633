.a-accordion-recipevariant {
  .t-heading--tinekjokken {
    padding-bottom: 1rem;
    padding-top: 2rem;
    border-top: 1px solid #00205b33;
    width: 75%;
    margin: 2rem auto auto;
  }

  .a-accordion__panel__widecontent {
    padding: 0;
  }

  .o-recipe-collection-list__widecontent {
    margin: 0;
  }

  .a-accordion__title-span {
    font-size: 1.25rem;
    padding: 17px 20px;
  }

  .a-accordion__triggericon {
    position: absolute;
    top: calc(50% - 20px);
    left: 10px;
    width: 40px;
    height: 40px;
  }

  .a-accordion__triggericon + .a-accordion__title-span {
    padding-left: 65px;
  }
}
