// Enabling sticky footer
// https://css-tricks.com/couple-takes-sticky-footer/ (Grid solution)
/* critical:start */
.l-body {
  &:focus {
    outline: none; // Removes the horizontal blue line in IE
  }

  &__main {
    display: flex;
    flex: 1 0 auto;
    width: 100%;
    align-self: center;
    position: relative;
    flex-direction: column;
    max-width: 1200px;
    background-color: white;
    z-index: 0;
  }
}
/* critical:end */
