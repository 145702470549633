// DEFAULT COLOR PALETTE
$color-text-lb: #222;
$color-secondary-text-lb: #666;
$color-body-text-wb: #001234;
$color-text-bp: #57401d;

$color-text-decoration-wb: #1458a5;
$color-call-to-action-wb: #3c78c1;
$color-call-to-action-strong: #16866e;
$color-31-ab: #6595d0;

$color-form-valid: green;
$color-form-invalid: red;
$color-form-error: #f8e8e8;

$color-header-border: #979797;
$color-light-background: #eaf1f9;
$color-light-background-bp: #f6f4f0;
$color-content-separator: #dfe2e9;
$color-inactive: #bebdbd;
$color-white: #fff;
$color-checkbox-hover: #ccc;

$color-baking-paper: #efece7;

$color-meny-logo: #ce0029;

//SEARCH
$color-search-background: #efefef;
$color-search-foreground: #6a6a6a;
$color-search-border: #e6e6e6;

// ALIASES
$color-recipe-border: $color-inactive;
