// base space units
$base-space: 8px;
$small-page-margin: $base-space * 3;

// heights
$page-header-height: 70px;
$page-header-height-mobile: 105px;
$vertical-space: $base-space * 3;

// widths
$horizontal-space: $base-space * 3;
$search-field-max-width: 500px;

// page widths
$recipe-overview-width: 500px;
$recipe-ingress-width: 665px;
$article-text-width: 700px;
$topic-text-width: 720px;
$default-content-width: 850px;
$topic-sentiment-width: 980px;
$recipe-main-width: 1136px;
$front-page-width: 1280px;
$page-max-width: 1488px;

// width variables from TINE Kjøkken CMS recipes
$header-media-width: 1190px;
$recipe-title: 850px;
$recipe-ingress: 665px;
// $comment-section: ?px; // 50% av side container
